import { DateRange, Info, Opacity, Send } from '@mui/icons-material';
import type { SelectChangeEvent } from '@mui/material';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import type { FC } from 'react';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PhoneNumbersInput } from './PhoneNumbersInput';
import type { FarmSettingsUpdateFunc } from './index';
// import ct from 'countries-and-timezones';
import { useSnackbar } from 'notistack';
const ct = require('countries-and-timezones');

const COUNTRIES_DICTIONARY = ct.getAllCountries() as Record<string, any>;
const COUNTRIES_LIST = Object.keys(COUNTRIES_DICTIONARY)
  .map((key) => COUNTRIES_DICTIONARY[key as keyof typeof COUNTRIES_DICTIONARY])
  .sort((a, b) => a.name.localeCompare(b.name));
// const COUNTRY_NAMES = COUNTRIES_LIST.map((country) => country.name);

interface IStyleProps {
  hasTitle?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  rightSpacing: {
    paddingRight: '4em',
  },
  nested: {
    width: '100%',
    paddingLeft: theme.spacing(7),
  },
  listRoot: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingTop: (props: IStyleProps) => (props.hasTitle ? 10 : 0),
    paddingBottom: 25,
  },
  listSubheaderRoot: {
    border: '1px solid #ddd',
    marginBottom: 5,
  },
}));

interface IGenericSwitchProps {
  id: string;
  title: string;
  description?: string;
  name: string;
  value: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IBooleanFarmSwitch {
  value: boolean;
  update: FarmSettingsUpdateFunc;
}

interface IStringFarmInput {
  value: string;
  update: FarmSettingsUpdateFunc;
}

export const GenericSwitch: FC<IGenericSwitchProps> = ({
  id,
  title,
  description,
  name,
  value,
  onChange,
  children,
}) => {
  return (
    <>
      {children && <ListItemIcon>{children}</ListItemIcon>}
      <ListItemText id={id} primary={title} secondary={description} style={{ maxWidth: '80%' }} />
      <ListItemSecondaryAction>
        <Switch
          checked={value}
          onChange={onChange}
          onClick={(e) => {
            e.stopPropagation();
          }}
          name={name}
          inputProps={{ 'aria-label': id }}
          color='secondary'
        />
      </ListItemSecondaryAction>
    </>
  );
};

export const DatePickerSwitch: FC<IBooleanFarmSwitch> = ({ value, update }) => {
  const intl = useIntl();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const datePickerStatus = event.target.checked;
    update((p) => ({ ...p, datePickerStatus }));
  };
  return (
    <GenericSwitch
      id={'date-picker-toggle'}
      // title={'Use Datepicker'}
      title={intl.formatMessage({ id: 'settings_use_datepicker' })}
      value={value}
      name={'datePickerStatus'}
      onChange={handleChange}
    >
      <DateRange />
    </GenericSwitch>
  );
};

export const PAWSwitch: FC<IBooleanFarmSwitch> = ({ value, update }) => {
  const intl = useIntl();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const PAWSwitchStatus = event.target.checked;
    update((p) => ({ ...p, PAWSwitchStatus }));
  };
  return (
    <GenericSwitch
      id={'PAW-toggle'}
      title={intl.formatMessage({ id: 'settings_paw_switch' })}
      value={value}
      onChange={handleChange}
      name={'PAWSwitchStatus'}
    >
      <Opacity />
    </GenericSwitch>
  );
};

export const FarmName: FC<IStringFarmInput> = ({ value, update }) => {
  const intl = useIntl();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const farmName = event.target.value;
    update((p) => ({ ...p, farmName }));
  };
  return (
    <TextField
      id='farm-name'
      variant='outlined'
      label={intl.formatMessage({ id: 'settings_farm_name' })}
      value={value}
      onChange={handleChange}
      fullWidth
    />
  );
};

export const WhatsappNotifications: FC<IBooleanFarmSwitch> = ({ value, update }) => {
  const intl = useIntl();
  const classes = useStyles({});
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enableWhatsappNotif = event.target.checked;
    update((p) => ({ ...p, enableWhatsappNotif }));
  };
  return (
    <>
      <ListItemIcon>
        <Send />
      </ListItemIcon>
      <ListItemText
        id='enable-whatsapp-notif'
        // primary='Enable instant notifications'
        // secondary='Receive messages instantly about critical events'
        primary={intl.formatMessage({ id: 'settings_enable_instant_notifications' })}
        secondary={intl.formatMessage({ id: 'settings_receive_messages_instantly' })}
        className={classes.rightSpacing}
      />
      <ListItemSecondaryAction>
        <Switch
          edge='end'
          checked={value}
          onChange={handleChange}
          name='enableWhatsappNotif'
          inputProps={{ 'aria-labelledby': 'whatsapp-toggle' }}
          color='secondary'
        />
      </ListItemSecondaryAction>
    </>
  );
};

const INSTANT_NOTIFICATION_CHANNELS = [
  ['whatsapp', 'WhatsApp'],
  ['sms', 'Text message'],
] as const;

export type InstantNotificationChannelType = typeof INSTANT_NOTIFICATION_CHANNELS[number][0];

interface IInstantNotificationChannelProps {
  value: InstantNotificationChannelType;
  update: FarmSettingsUpdateFunc;
}
export const InstantNotificationChannel: FC<IInstantNotificationChannelProps> = ({ value, update }) => {
  const classes = useStyles({});
  const intl = useIntl();
  const handleChange = (event: SelectChangeEvent<string>) => {
    const instantNotificationChannel = event.target.value as InstantNotificationChannelType;
    update((p) => ({ ...p, instantNotificationChannel }));
  };
  // const label = 'Instant notifications channel';
  const label = intl.formatMessage({ id: 'settings_instant_notifications_channel' });

  return (
    <div className={classes.nested} style={{ display: 'grid' }}>
      <FormControl variant='outlined'>
        <InputLabel shrink id='selected-instant-notification-channel-label'>
          {label}
        </InputLabel>
        <Select
          placeholder={label}
          labelId='selected-instant-notification-channel-label'
          label={label}
          id='selected-instant-notification-channel'
          displayEmpty
          value={value}
          onChange={handleChange}
          fullWidth
        >
          {INSTANT_NOTIFICATION_CHANNELS.map(([channel, label]) => {
            return (
              <MenuItem key={channel} value={channel}>
                {intl.formatMessage({ id: label, defaultMessage: label })}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>
          <FormattedMessage id='settings_choose_how_to_receive_the_instant_notifications' />
        </FormHelperText>
      </FormControl>
    </div>
  );
};

interface ICountrySelectorProps {
  value: string | undefined;
  update: FarmSettingsUpdateFunc;
}
export const CountrySelector: FC<ICountrySelectorProps> = ({ value, update }) => {
  const intl = useIntl();
  const handleChange = (event: SelectChangeEvent<string>) => {
    const country = event.target.value as string | undefined;
    update((p) => ({ ...p, country }));
  };
  const label = intl.formatMessage({ id: 'country' });

  // if no country is selected preselect
  // using browser's timezone
  useEffect(() => {
    if (!value) {
      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const country = ct.getCountryForTimezone(browserTimezone)?.name;
      console.log({ country }, { browserTimezone });
      update((p) => ({ ...p, country }));
    }
  }, [value, update]);

  return (
    <FormControl variant='outlined' fullWidth>
      <InputLabel shrink id='selected-timezone-label'>
        {label}
      </InputLabel>
      <Select
        placeholder={label}
        labelId='selected-timezone-label'
        label={label}
        id='selected-timezone'
        displayEmpty
        value={value}
        onChange={handleChange}
        fullWidth
      >
        <MenuItem value={undefined}>
          <em>
            <FormattedMessage id='not_specified' />
          </em>
        </MenuItem>
        {COUNTRIES_LIST.map(
          (
            country: {
              id: string;
              name: string;
              timezones: string[];
            },
            i: number
          ) => {
            return (
              <MenuItem key={i + 1} value={country.name}>
                <FormattedMessage id={country.name.toLowerCase()} defaultMessage={country.name} />
              </MenuItem>
            );
          }
        )}
      </Select>
      <FormHelperText>
        <FormattedMessage id='settings_this_setting_will_be_used_to_set_the_timezone_for_the_notifications' />
      </FormHelperText>
    </FormControl>
  );
};

interface IWhatsappNumbersProps {
  value: string[];
  update: FarmSettingsUpdateFunc;
}

const MAX_PHONE_NUMBERS = 10;
export const WhatsappNumbers: FC<IWhatsappNumbersProps> = ({ value, update }) => {
  const classes = useStyles({});
  const intl = useIntl();
  const snackbar = useSnackbar();
  const handleChange = (phoneNumber: string) => {
    const updatedNumbers = [...value];
    if (updatedNumbers.length >= MAX_PHONE_NUMBERS) {
      snackbar.enqueueSnackbar(
        intl.formatMessage({ id: 'settings_max_phone_numbers_reached' }, { max: MAX_PHONE_NUMBERS }),
        {
          variant: 'warning',
        }
      );
      return;
    }
    updatedNumbers.push(phoneNumber);
    update((p) => ({ ...p, phoneNumbers: updatedNumbers }));
  };
  const handleDeleteChip = (phoneNumber: string) => {
    const enabledNumbers = [...value];
    const index = enabledNumbers.indexOf(phoneNumber);
    if (index > -1) {
      enabledNumbers.splice(index, 1);
    }
    update((p) => ({ ...p, phoneNumbers: enabledNumbers }));
  };
  return (
    <div className={classes.nested}>
      <PhoneNumbersInput
        // label='Phone Numbers'
        label={intl.formatMessage({ id: 'settings_phone_numbers' })}
        // placeholder='Type and press Enter to add numbers'
        placeholder={intl.formatMessage({ id: 'settings_type_and_press_enter_to_add_numbers' })}
        value={value}
        onAdd={handleChange}
        onDelete={handleDeleteChip}
      />
    </div>
  );
};

interface ITooltipProps {
  title: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
    | undefined;
  fontSize?: 'small' | 'inherit' | 'large' | undefined;
}

export const TooltipElement: FC<ITooltipProps> = ({ title, placement = 'top', fontSize = 'small' }) => {
  return (
    <Tooltip placement={placement} title={title} style={{ marginLeft: -4 }} disableInteractive>
      <Info fontSize={fontSize} />
    </Tooltip>
  );
};

export const CustomList: FC<{ title?: string }> = ({ children, title }) => {
  const classes = useStyles({ hasTitle: Boolean(title) });

  let ch: React.ReactNode[];
  if (React.Children.count(children) <= 1) {
    ch = [children];
  } else {
    ch = children as React.ReactNode[];
  }

  return (
    <List
      subheader={title ? <ListSubheader className={classes.listSubheaderRoot}>{title}</ListSubheader> : undefined}
      className={classes.listRoot}
    >
      {ch && ch.flat().map((c: React.ReactNode, i: number) => <ListItem key={i}>{c}</ListItem>)}
    </List>
  );
};
